import {
  PiNumberCircleOneBold,
  PiNumberCircleTwoBold,
  PiNumberCircleThreeBold,
  PiNumberCircleFourBold,
  PiNumberCircleFiveBold,
} from "react-icons/pi";

import bulldog1 from "../assets/individual-project/bulldog/bulldog1.png";
import bulldog2 from "../assets/individual-project/bulldog/bulldog2.png";
import bulldog3 from "../assets/individual-project/bulldog/bulldog3.png";
import bulldogM from "../assets/individual-project/bulldog/mobile.png";

import foodie1 from "../assets/individual-project/foodie/foodie1.png";
import foodie2 from "../assets/individual-project/foodie/foodie2.png";
import foodie3 from "../assets/individual-project/foodie/foodie3.png";
import foodieM from "../assets/individual-project/foodie/mobile.png";

import iviry1 from "../assets/individual-project/iviry/iviry1.png";
import iviry2 from "../assets/individual-project/iviry/iviry2.png";
import iviry3 from "../assets/individual-project/iviry/iviry3.png";
import iviryM from "../assets/individual-project/iviry/mobile.png";

import eee1 from "../assets/portfolio/eee1.png";
import eee2 from "../assets/portfolio/eee2.png";


export const portfolios = [
    // buldog start
    {
      whatwedo: bulldogM,
      images: [bulldog1, bulldog2, bulldog3],
      name: "Bulldogs-Estates",
      description:
        "Bulldogs-estates.com specializes in selling tailgating lots. They originally had a website, but it failed to drive any meaningful traffic or meet their specific needs. That's when they decided to approach Innovative MOJO for a full-scale digital transformation.",
      process:
        "We built a brand-new website from scratch. Our team started with market research and user experience design. We then developed an interactive map feature using React to display available lots, integrated a digital contract-signing mechanism, and incorporated a secure payment gateway.",
      processes: [
        {
          icon: <PiNumberCircleOneBold />,
          title: "Market Research",
          desc: "Conducted comprehensive market research to identify client needs and market gaps.",
        },
        {
          icon: <PiNumberCircleTwoBold />,
          title: "UX Design",
          desc: "Initiated user experience design, focusing on usability and customer satisfaction.",
        },
        {
          icon: <PiNumberCircleThreeBold />,
          title: "Development Phase",
          desc: " Utilized React for developing an interactive map to display available lots.",
        },
        {
          icon: <PiNumberCircleFourBold />,
          title: "Contract Signing",
          desc: "Integrated a digital contract-signing mechanism to streamline the booking process.",
        },
        {
          icon: <PiNumberCircleFiveBold />,
          title: "Payment Gateway",
          desc: "Incorporated a secure payment system to facilitate smooth transactions.",
        },
      ],
      result:
        "Post-launch, the new website saw immediate results. In just two months, Bulldogs-estates.com generated over $500k in revenue and saw significant increases in user engagement and conversion rates.",
  
      results: [
        "Revenue: $500k in 2 months",
        "Revenue in 2 years 1.76M",
        "User Engagement: Increased by 60%",
        "Conversion Rate: Up by 25%",
        "Sold 22 lots ",
      ],
  
      testimonial: {
        text: "Innovative MOJO not only are diverse but responsive and reliable.",
        link: "",
      },
    },
    // bulldog end


  // content left
  {
    whatwedo: bulldogM,
    images: [bulldog1, bulldog2, bulldog3],
    name: "Bulldogs-Estates",
    description:
      "Bulldogs-estates.com specializes in selling tailgating lots. They originally had a website, but it failed to drive any meaningful traffic or meet their specific needs. That's when they decided to approach Innovative MOJO for a full-scale digital transformation.",
    process:
      "We built a brand-new website from scratch. Our team started with market research and user experience design. We then developed an interactive map feature using React to display available lots, integrated a digital contract-signing mechanism, and incorporated a secure payment gateway.",
    processes: [
      {
        icon: <PiNumberCircleOneBold />,
        title: "Market Research",
        desc: "Conducted comprehensive market research to identify client needs and market gaps.",
      },
      {
        icon: <PiNumberCircleTwoBold />,
        title: "UX Design",
        desc: "Initiated user experience design, focusing on usability and customer satisfaction.",
      },
      {
        icon: <PiNumberCircleThreeBold />,
        title: "Development Phase",
        desc: " Utilized React for developing an interactive map to display available lots.",
      },
      {
        icon: <PiNumberCircleFourBold />,
        title: "Contract Signing",
        desc: "Integrated a digital contract-signing mechanism to streamline the booking process.",
      },
      {
        icon: <PiNumberCircleFiveBold />,
        title: "Payment Gateway",
        desc: "Incorporated a secure payment system to facilitate smooth transactions.",
      },
    ],
    result:
      "Post-launch, the new website saw immediate results. In just two months, Bulldogs-estates.com generated over $500k in revenue and saw significant increases in user engagement and conversion rates.",

    results: [
      "Revenue: $500k in 2 months",
      "Revenue in 2 years 1.76M",
      "User Engagement: Increased by 60%",
      "Conversion Rate: Up by 25%",
      "Sold 22 lots ",
    ],

    testimonial: {
      text: "Innovative MOJO not only are diverse but responsive and reliable.",
      link: "",
    },
  },

  {
    images: [eee1, eee2, eee2],
    // whatwedo: iviryM,
    name: "EEE Corp",
    description:
      "Iviry is a tech-focused company that developed software for IT specialists, designed to help them manage and maintain IT services for their clients. While the software was sophisticated and functional, Iviry faced challenges in distributing it to a wider audience.",
    process:
      "Innovative MOJO partnered with Iviry to understand software capabilities and target audience and undertook the following steps to meet Iviry's needs:",
    processes: [
      {
        icon: <PiNumberCircleOneBold />,
        title: "Design",
        desc: "Designed a new website that captured current services and implemented new ones seamlessly.",
      },

      {
        icon: <PiNumberCircleTwoBold />,
        title: "Marketing",
        desc: "Developed a multi-channel marketing strategy.",
      },
      {
        icon: <PiNumberCircleThreeBold />,
        title: "SEO",
        desc: "SEO-optimized content",
      },
      {
        icon: <PiNumberCircleFourBold />,
        title: "Outreach",
        desc: "Partnership Outreach",
      },
      {
        icon: <PiNumberCircleFiveBold />,
        title: "Campaign Management",
        desc: "Managed campaign rollouts and monitored performance metrics.",
      },
    ],
    result: "The collaboration was a resounding success:",

    results: [
      "Significantly increased user engagement and downloads.",
      "Enabled Iviry to break into new markets, expanding its customer base.",
      "Improved to first page for Google Searches.",
    ],

    testimonial: {
      text: "Thanks to Innovative MOJO's expertise in market penetration, Iviry has successfully expanded its reach and can now assist more IT specialists in delivering top-notch service to their clients Competence from a business partner is a baseline expectation as you embark on a project together, and when distinguishable expertise upon execution of services is demonstrated the experience becomes that much more gratifying. Trust and accountability, however, cannot be fully quantified until each organization is fully engaged in the doing. On those fronts, Innovative MOJO, in the delivery of all the various services they provide to our organization, has no equal. We could not possibly overstate the value they bring to our business, or the peace of mind associated with knowing they are in our corner.-Joseph Browne Iviry.com.",
      link: "",
    },
  },

   {
    images: [iviry1, iviry2, iviry3],
    whatwedo: iviryM,
    name: "Iviry",
    description:
      "Iviry is a tech-focused company that developed software for IT specialists, designed to help them manage and maintain IT services for their clients. While the software was sophisticated and functional, Iviry faced challenges in distributing it to a wider audience.",
    process:
      "Innovative MOJO partnered with Iviry to understand software capabilities and target audience and undertook the following steps to meet Iviry's needs:",
    processes: [
      {
        icon: <PiNumberCircleOneBold />,
        title: "Design",
        desc: "Designed a new website that captured current services and implemented new ones seamlessly.",
      },

      {
        icon: <PiNumberCircleTwoBold />,
        title: "Marketing",
        desc: "Developed a multi-channel marketing strategy.",
      },
      {
        icon: <PiNumberCircleThreeBold />,
        title: "SEO",
        desc: "SEO-optimized content",
      },
      {
        icon: <PiNumberCircleFourBold />,
        title: "Outreach",
        desc: "Partnership Outreach",
      },
      {
        icon: <PiNumberCircleFiveBold />,
        title: "Campaign Management",
        desc: "Managed campaign rollouts and monitored performance metrics.",
      },
    ],
    result: "The collaboration was a resounding success:",

    results: [
      "Significantly increased user engagement and downloads.",
      "Enabled Iviry to break into new markets, expanding its customer base.",
      "Improved to first page for Google Searches.",
    ],

    testimonial: {
      text: "Thanks to Innovative MOJO's expertise in market penetration, Iviry has successfully expanded its reach and can now assist more IT specialists in delivering top-notch service to their clients Competence from a business partner is a baseline expectation as you embark on a project together, and when distinguishable expertise upon execution of services is demonstrated the experience becomes that much more gratifying. Trust and accountability, however, cannot be fully quantified until each organization is fully engaged in the doing. On those fronts, Innovative MOJO, in the delivery of all the various services they provide to our organization, has no equal. We could not possibly overstate the value they bring to our business, or the peace of mind associated with knowing they are in our corner.-Joseph Browne Iviry.com.",
      link: "",
    },
  },

  {
    images: [iviry1, iviry2, iviry3],
    whatwedo: iviryM,
    name: "Iviry",
    description:
      "Iviry is a tech-focused company that developed software for IT specialists, designed to help them manage and maintain IT services for their clients. While the software was sophisticated and functional, Iviry faced challenges in distributing it to a wider audience.",
    process:
      "Innovative MOJO partnered with Iviry to understand software capabilities and target audience and undertook the following steps to meet Iviry's needs:",
    processes: [
      {
        icon: <PiNumberCircleOneBold />,
        title: "Design",
        desc: "Designed a new website that captured current services and implemented new ones seamlessly.",
      },

      {
        icon: <PiNumberCircleTwoBold />,
        title: "Marketing",
        desc: "Developed a multi-channel marketing strategy.",
      },
      {
        icon: <PiNumberCircleThreeBold />,
        title: "SEO",
        desc: "SEO-optimized content",
      },
      {
        icon: <PiNumberCircleFourBold />,
        title: "Outreach",
        desc: "Partnership Outreach",
      },
      {
        icon: <PiNumberCircleFiveBold />,
        title: "Campaign Management",
        desc: "Managed campaign rollouts and monitored performance metrics.",
      },
    ],
    result: "The collaboration was a resounding success:",

    results: [
      "Significantly increased user engagement and downloads.",
      "Enabled Iviry to break into new markets, expanding its customer base.",
      "Improved to first page for Google Searches.",
    ],

    testimonial: {
      text: "Thanks to Innovative MOJO's expertise in market penetration, Iviry has successfully expanded its reach and can now assist more IT specialists in delivering top-notch service to their clients Competence from a business partner is a baseline expectation as you embark on a project together, and when distinguishable expertise upon execution of services is demonstrated the experience becomes that much more gratifying. Trust and accountability, however, cannot be fully quantified until each organization is fully engaged in the doing. On those fronts, Innovative MOJO, in the delivery of all the various services they provide to our organization, has no equal. We could not possibly overstate the value they bring to our business, or the peace of mind associated with knowing they are in our corner.-Joseph Browne Iviry.com.",
      link: "",
    },
  },

  {
    whatwedo: foodieM,
    images: [foodie1, foodie2, foodie3],
    name: "Foodie Not a Chef",
    description:
      "Tiffany is a culinary enthusiast who had a strong Instagram following but lacked a dedicated platform to comprehensively showcase her recipes and generate revenue. She sought out Innovative MOJO to build her online brand, drive more attention, and create additional streams of income.",
    process:
      "Innovative MOJO undertook the following steps to meet Tiffany's needs: Conducted an in-depth analysis to understand the target audience and their engagement with culinary content.",
    processes: [
      {
        icon: <PiNumberCircleOneBold />,
        title: "Design",
        desc: "Designed a user-friendly website focusing on optimal user experience and ease of navigation",
      },
      {
        icon: <PiNumberCircleTwoBold />,
        title: "Blog Creation",
        desc: "Added a blog section dedicated to Tiffany's diverse recipes and culinary tips.",
      },
      {
        icon: <PiNumberCircleThreeBold />,
        title: "Receipe Format",
        desc: "Developed a structured, simplified recipe format to facilitate user engagement.",
      },
      {
        icon: <PiNumberCircleFourBold />,
        title: "Revenue Enhancement",
        desc: "Incorporated referral and sponsored content options to generate additional revenue streams.",
      },
      {
        icon: <PiNumberCircleFiveBold />,
        title: "Meeting Objectives",
        desc: "By focusing on these key areas, Innovative MOJO created a comprehensive online platform that effectively met Tiffany's objectives.",
      },
    ],
    result: "The new website had an immediate impact:",

    results: [
      "Gained 3 new sponsors within 4 months.",
      "Enhanced brand awareness ",
      "Extended reach beyond her Instagram audience.",
    ],

    testimonial: {
      text: "Tiffany's collaboration with Innovative MOJO has not only given her a new platform to express her culinary creativity but also significantly contributed to her brand's financial growth.",
      link: "",
    },
  },

   {
    images: [iviry1, iviry2, iviry3],
    whatwedo: iviryM,
    name: "Iviry",
    description:
      "Iviry is a tech-focused company that developed software for IT specialists, designed to help them manage and maintain IT services for their clients. While the software was sophisticated and functional, Iviry faced challenges in distributing it to a wider audience.",
    process:
      "Innovative MOJO partnered with Iviry to understand software capabilities and target audience and undertook the following steps to meet Iviry's needs:",
    processes: [
      {
        icon: <PiNumberCircleOneBold />,
        title: "Design",
        desc: "Designed a new website that captured current services and implemented new ones seamlessly.",
      },

      {
        icon: <PiNumberCircleTwoBold />,
        title: "Marketing",
        desc: "Developed a multi-channel marketing strategy.",
      },
      {
        icon: <PiNumberCircleThreeBold />,
        title: "SEO",
        desc: "SEO-optimized content",
      },
      {
        icon: <PiNumberCircleFourBold />,
        title: "Outreach",
        desc: "Partnership Outreach",
      },
      {
        icon: <PiNumberCircleFiveBold />,
        title: "Campaign Management",
        desc: "Managed campaign rollouts and monitored performance metrics.",
      },
    ],
    result: "The collaboration was a resounding success:",

    results: [
      "Significantly increased user engagement and downloads.",
      "Enabled Iviry to break into new markets, expanding its customer base.",
      "Improved to first page for Google Searches.",
    ],

    testimonial: {
      text: "Thanks to Innovative MOJO's expertise in market penetration, Iviry has successfully expanded its reach and can now assist more IT specialists in delivering top-notch service to their clients Competence from a business partner is a baseline expectation as you embark on a project together, and when distinguishable expertise upon execution of services is demonstrated the experience becomes that much more gratifying. Trust and accountability, however, cannot be fully quantified until each organization is fully engaged in the doing. On those fronts, Innovative MOJO, in the delivery of all the various services they provide to our organization, has no equal. We could not possibly overstate the value they bring to our business, or the peace of mind associated with knowing they are in our corner.-Joseph Browne Iviry.com.",
      link: "",
    },
  },
];

export const initial = {
  images: [],
  whatwedo: "",
  name: "",
  description: "",
  process: "",
  processes: [
    {
      title: "",
      desc: "",
    },
  ],
  result: "",
  results: [""],
  testimonial: {
    text: "",
    link: "",
  },
};
