import React from 'react'
import './Transformation.css';

const Transformation = () => {
  
  return (
<>

<div className=' Transformation'   data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out">
      <h1 className='text-center heading-Transformation' >The <span className="goldcolor">MOJO</span> Digital Transformation Package</h1>
     {/* <div className="d-flex justify-content-center">
     <p className='text-center para mt-5 mb-4 Transformation-para-width'>
       Our package is a bold promise to CEOs, marketers, directors, and decision makers across industries. We champion the business owners, marketing executives, and e-commerce entrepreneurs.
        </p>
     </div>
      <h2 className='text-center para2'>Here’s what the Innovative MOJO promise includes:</h2> */}
    </div>
</>
  )
}

export default Transformation
