import React from "react";

import HomeFooter from "../../components/home-footer/home-footer.component";
import SmallBanner from "../../components/small-banner/small-banner.component";
import Projects from "../../components/projects/projects.component";

import "./portfolio.styles.scss";
const Portfolio = () => {
  return (
    <div className="portfolio-component">
      <SmallBanner
        title="Case Studies"
        heading="At Innovative MOJO, we bring more than 20 years of experience to the table—but we believe that the cornerstone of our success lies in real conversations, with real people, tackling real challenges."
      />
      <Projects />
      <HomeFooter />
    </div>
  );
};

export default Portfolio;
