import React from "react";

import BackgroundEffect from "../background-effect/background-effect.component";
import arrow from "../../assets/arrow.svg";

import "./news-list.styles.scss";
import { useNavigate } from "react-router-dom";

const NewsList = ({ articles }) => {
  const navigate = useNavigate();
  return (
    <div className="news-list-component">
      <BackgroundEffect />
      <div className="container row">
        {articles.map((articles, i) => (
          <div className="col-lg-4 col-12 news" key={i}>
            <div
              className="news-container"
              onClick={() => navigate(`/mojo-news/${articles.id}`)}
            >
              <img className="news-icon" alt="" src={articles.coverPhotoURL} />

              <h3>{articles.title}</h3>
              <div className="news-description">{articles.summary}</div>
              <p>
                Read More <img src={arrow} alt="" />
              </p>
            </div>
          </div>
        ))}
      </div>
      <div className="bottom">
        <BackgroundEffect />
      </div>
    </div>
  );
};

export default NewsList;
