import React from "react";

import HomeFooter from "../../components/home-footer/home-footer.component";
import SmallBanner from "../../components/small-banner/small-banner.component";
import ServicesList from "../../components/services-list/services-list.component";
import { Helmet } from "react-helmet";
import "./services.styles.scss";
const Services = () => {
  return (
    <div className="services-component">
      <Helmet>
        <title>
          React Web Design | SEO | Mobile App Development | Innovative Mojo
        </title>
        <meta
          name="description"
          content="Discover cutting-edge solutions in React web design, SEO optimization, and mobile app development with Innovative Mojo."
        />
        <meta
          name="keywords"
          content="Digital Marketing Company,Digital Marketing Services,Digital Marketing Agency,Website Maintenance Company,Seo Services,Mobile App Development Services, Search Engine Optimization Services, Website Maintenance Services, Mobile App Development Company, Android App Development Service, React Js Development Company, Seo Company, Seo Optimization Company, Ios App Development Services, Mobile App Maintenance Services"
        />
      </Helmet>
      <SmallBanner
        title="We Give a Value and Solution, not only Design."
        heading="At Innovative Mojo, we bring more than 20 years of experience to the table—but we believe that the cornerstone of our success lies in real conversations, with real people, tackling real challenges."
      />
      <ServicesList />
      <HomeFooter />
    </div>
  );
};

export default Services;
