import { useEffect, useState } from "react";
import { Nav } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import logo from "../../assets/home/logo.svg";
import PrimaryButton from "../button-primary/button-primary.component";
import toggler from "../../assets/home/toggler.svg";
import { IoClose } from "react-icons/io5";
import arrow from "../../assets/newpage/arrow.svg";

import "./navbar.styles.scss";
const NavbarrCopy = () => {
  const navigate = useNavigate();
  const [showNav, setShowNav] = useState(false);
  useEffect(() => {
    if (showNav) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [showNav]);
  return (
    <div className="navbar-mobile-desktop">
      <div className="navbar-desktop">
        <Navbar expand="lg">
          <Container>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Brand onClick={() => navigate("/")}>
              <img src={logo} alt="" />
            </Navbar.Brand>
            <Navbar.Collapse
              id="navbarScroll"
              className="justify-content-between"
            >
              <Nav className="mx-auto">
                <Nav.Link onClick={() => navigate("/")}>Home</Nav.Link>
                {/* <Nav.Link onClick={() => navigate("/services")}>
                  Services
                </Nav.Link> */}
                <Nav.Link onClick={() => navigate("/case-studies")}>
                  Case Studies
                </Nav.Link>
                {/* <Nav.Link onClick={() => navigate("/about")}>About</Nav.Link> */}
                {/* <Nav.Link onClick={() => navigate("/faq")}>FAQs</Nav.Link> */}
                <Nav.Link onClick={() => navigate("/mojo-news")}>
                  MOJO News
                </Nav.Link>
                <Nav.Link onClick={() => navigate("/faq")}>Faq</Nav.Link>
                {/* <Nav.Link onClick={() => navigate("/contact")}>
                 Contact Us
                </Nav.Link> */}
              </Nav>
              <div>
              <Nav.Link href="/booking">
              <PrimaryButton
               
              >
               Lets Discuss
              </PrimaryButton>
            </Nav.Link>
              </div>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      <div className="navbar-mobile">
        {showNav ? (
          <div onClick={() => setShowNav(!showNav)} className="toggler">
            <IoClose className="mob-menu-close-icon" />
          </div>
        ) : (
          <img
            className="toggler"
            onClick={() => setShowNav(!showNav)}
            src={toggler}
            alt=""
          />
        )}
        {showNav && (
          <nav className="sidebar-nav">
            <div onClick={() => navigate("/")}>
              <img src={logo} alt="" />
            </div>
            <ul>
              {/* <li onClick={() => navigate("/services")}>
                <span>Services</span>
              </li> */}
              <li onClick={() => navigate("/")}>
                <span>Home</span>
              </li>

              <li onClick={() => navigate("/case-studies")}>
                <span>Case Studies</span>
              </li>
              <li onClick={() => navigate("/mojo-news")}>
                <span>MOJO News</span>
              </li>
              <li onClick={() => navigate("/FAQ")}>
                <span>Faq</span>
              </li>
              {/* 
              <li onClick={() => navigate("/about")}>
                <span>About</span>
              </li>
              <li onClick={() => navigate("/contact")}>
                <span>Contact Us</span>
              </li>

              <li onClick={() => navigate("/faq")}>
                <span>FAQ</span>
              </li> */}
            </ul>
           
            <Nav.Link href="/booking">
              <PrimaryButton
               
              >
               Lets Discuss
              </PrimaryButton>
            </Nav.Link>
          </nav>
        )}
      </div>
    </div>
  );
};

export default NavbarrCopy;
