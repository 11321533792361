import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Keyboard, Navigation, Scrollbar } from "swiper/modules";

import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./Slider.css";
// import slidePicnew from "../../assets/dald.png";
import slidePicnew from "../../../assets/newpage/dald.png";
import slidePicnew2 from "../../../assets/newpage/iviry (1).png";
import slidePicnewfoodie from "../../../assets/newpage/foodie.png";
import slidePicneweeecorp from "../../../assets/newpage/shape-eee.png";
import bulklogo from "../../../assets/newpage/bulk-logo.png";

import movenextbtn from "../../../assets/newpage/moveNext.svg";
import moveprevbtn from "../../../assets/newpage/moveBack.svg";
import goldcircle from "../../../assets/newpage/goldcircle.png";
import arrow from "../../../assets/newpage/arrow.svg";

const Slider = () => {
  const swiper = useSwiper();

  const handleprevbtn = () => {
    const nextButton = document.querySelector(".custom-next");
    const prevButton = document.querySelector(".custom-prev");
  
    // Check if the screen width is less than or equal to 768px (typical mobile breakpoint)
    const isMobileScreen = window.innerWidth <= 768;
  
    const zIndexValue = isMobileScreen ? 80 : 0;
  
    prevButton.style.zIndex = zIndexValue;
    nextButton.style.zIndex = zIndexValue;
  
    setTimeout(() => {
      swiper?.slidePrev();
      console.log("prev button clicked");
      prevButton.style.zIndex = 80;
      nextButton.style.zIndex = 80;
    }, 1000);
  };
  
  const handleNextvbtn = () => {
    const nextButton = document.querySelector(".custom-next");
    const prevButton = document.querySelector(".custom-prev");
  
    // Check if the screen width is less than or equal to 768px (typical mobile breakpoint)
    const isMobileScreen = window.innerWidth <= 768;
  
    const zIndexValue = isMobileScreen ? 80 : 0;
  
    prevButton.style.zIndex = zIndexValue;
    nextButton.style.zIndex = zIndexValue;
  
    setTimeout(() => {
      swiper?.slidePrev();
      console.log("next button clicked");
      prevButton.style.zIndex = 80;
      nextButton.style.zIndex = 80;
    }, 700);
  };
  
  return (
    <>
      <div
        className="parent-div   overflow-hidden "
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
      >
        <img className="sliderbg" src={goldcircle} alt="" />

        <div className="swiper-main-div">
          <img
            className="custom-prev"
            onClick={handleprevbtn}
            src={moveprevbtn}
            alt="moveprevbtn"
          />
          <div className="parent-outer-div"></div>
          <Swiper
            breakpoints={{
              768: {
                slidesPerView: 1,
              },
              780: {
                slidesPerView: 3,
              },
            }}
            slidesPerView={3}
            speed={1000}
            autoplay={{
              delay: 1000,
            }}
            initialSlide={1}
            centeredSlides={true}
            loop={true}
            navigation={{
              nextEl: ".custom-next",
              prevEl: ".custom-prev",
            }}
            modules={[Keyboard, Navigation, Scrollbar]}
            className="mySwiper"
          >
            <SwiperSlide>
              <div className="slide-container">
                <div className="outer-div">
                  <img
                    className="work-slide-image"
                    src={slidePicnewfoodie}
                    alt="this is a slider "
                    width="309px"
                    height="420px"
                  />
                </div>
                <div className="slider-footer-div">
                  <p className="slider-footer-para text-center">
                    We Told A Creator's Story Through Food
                  </p>
                  {/* <button className="slider-footer-button">
                    See Full Work
                  </button> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="slide-container">
                <div className="outer-div">
                  <img
                    className="work-slide-image"
                    src={slidePicnew2}
                    alt="this is a slider "
                    width="309px"
                    height="420px"
                  />
                </div>
                <div className="slider-footer-div">
                  <p className="slider-footer-para text-center">
                    We Helped Streamline Cyber Security
                  </p>
                  {/* <button className="slider-footer-button">
                    See Full Work
                  </button> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="slide-container">
                <div className="outer-div">
                  <img
                    className="work-slide-image"
                    src={slidePicnew}
                    alt="this is a slider "
                    width="309px"
                    height="420px"
                  />
                </div>
                <div className="slider-footer-div">
                  <p className="slider-footer-para text-center">
                    We Made RV Parks Cool.
                  </p>
                  {/* <button className="slider-footer-button">
                    See Full Work
                  </button> */}
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="slide-container">
                <div className="outer-div">
                  <img
                    className="work-slide-image"
                    src={slidePicneweeecorp}
                    alt="this is a slider "
                    width="309px"
                    height="420px"
                  />
                </div>
                <div className="slider-footer-div">
                  <p className="slider-footer-para text-center eeecorpheading ">
                    We Built The Digital Bridge For Global Healthcare
                    Innovation.
                  </p>
                  {/* <button className="slider-footer-button">
                    See Full Work
                  </button> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="slide-container">
                <div className="outer-div">
                  <img
                    className="work-slide-image"
                    src={bulklogo}
                    alt="this is a slider "
                    width="309px"
                    height="420px"
                  />
                </div>
                <div className="slider-footer-div">
                  <p className="slider-footer-para text-center eeecorpheading ">
                  We Delivered a Digital Highway for Trucking
                  </p>
                  {/* <button className="slider-footer-button">
                    See Full Work
                  </button> */}
                </div>
              </div>
            </SwiperSlide>

            {/* repeat */}
            <SwiperSlide>
              <div className="slide-container">
                <div className="outer-div">
                  <img
                    className="work-slide-image"
                    src={slidePicnewfoodie}
                    alt="this is a slider "
                    width="309px"
                    height="420px"
                  />
                </div>
                <div className="slider-footer-div">
                  <p className="slider-footer-para text-center">
                    We Told A Creator's Story Through Food
                  </p>
                  {/* <button className="slider-footer-button">
                    See Full Work
                  </button> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="slide-container">
                <div className="outer-div">
                  <img
                    className="work-slide-image"
                    src={slidePicnew2}
                    alt="this is a slider "
                    width="309px"
                    height="420px"
                  />
                </div>
                <div className="slider-footer-div">
                  <p className="slider-footer-para text-center">
                    We Helped Streamline Cyber Security
                  </p>
                  {/* <button className="slider-footer-button">
                    See Full Work
                  </button> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="slide-container">
                <div className="outer-div">
                  <img
                    className="work-slide-image"
                    src={slidePicnew}
                    alt="this is a slider "
                    width="309px"
                    height="420px"
                  />
                </div>
                <div className="slider-footer-div">
                  <p className="slider-footer-para text-center">
                    We Made RV Parks Cool.
                  </p>
                  {/* <button className="slider-footer-button">
                    See Full Work
                  </button> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="slide-container">
                <div className="outer-div">
                  <img
                    className="work-slide-image"
                    src={slidePicneweeecorp}
                    alt="this is a slider "
                    width="309px"
                    height="420px"
                  />
                </div>
                <div className="slider-footer-div">
                  <p className="slider-footer-para text-center eeecorpheading ">
                    We Built The Digital Bridge For Global Healthcare
                    Innovation.
                  </p>
                  {/* <button className="slider-footer-button">
                    See Full Work
                  </button> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="slide-container">
                <div className="outer-div">
                  <img
                    className="work-slide-image"
                    src={bulklogo}
                    alt="this is a slider "
                    width="309px"
                    height="420px"
                  />
                </div>
                <div className="slider-footer-div">
                  <p className="slider-footer-para text-center eeecorpheading ">
                  We Delivered a Digital Highway for Trucking
                  </p>
                  {/* <button className="slider-footer-button">
                    See Full Work
                  </button> */}
                </div>
              </div>
            </SwiperSlide>
          </Swiper>

          <img
            className="custom-next"
            onClick={handleNextvbtn}
            src={movenextbtn}
            alt="movenextbtn"
          />
        </div>
      </div>
      <div className="slider-footer-btn-div gap-3  ">
        <a href="/case-studies">
          <button className="btncontact">See Our Work</button>
        </a>
        <a href="/booking">
          <button className="btncontact ml-2">
            Lets Discuss
            <img className="ml-2 button-arrow" src={arrow} alt="" />
          </button>
        </a>
      </div>
    </>
  );
};

export default Slider;







