import React from "react";

import FAQ from "../../components/faq/faq.component";
import HomeFooter from "../../components/home-footer/home-footer.component";
import SmallBanner from "../../components/small-banner/small-banner.component";

import "./faq.styles.scss";
const FAQQ = () => {
  return (
    <div className="faq-page">
      <SmallBanner
        title="Our All-in-One Digital Solution"
        heading="At Innovative Mojo, we bring more than 20 years of experience to the table—but we believe that the cornerstone of our success lies in real conversations, with real people, tackling real challenges."
      />
      <FAQ />
      <HomeFooter />
    </div>
  );
};

export default FAQQ;
