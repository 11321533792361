import React from "react";

import HomeFooter from "../../components/home-footer/home-footer.component";
import SmallBanner from "../../components/small-banner/small-banner.component";
import BenefitsSection from "../../components/benefits-section/benefits-section.component";
import ProudToWorkSection from "../../components/proud-to-work-section/proud-to-work-section.component";

import "./about.styles.scss";

const About = () => {
  return (
    <div className="contact-component">
      <SmallBanner
        title="Who We Are: Where Business Challenges Meet Digital Solutions"
        subtext="At Innovative Mojo, we bring more than 20 years of experience to the table—but we believe that the cornerstone of our success lies in real conversations, with real people, tackling real challenges."
      />
      <ProudToWorkSection />
      <BenefitsSection />
      <HomeFooter />
    </div>
  );
};

export default About;
