import logo from "../../assets/home/logo-white.svg";
import BackgroundEffect from "../background-effect/background-effect.component";
import { useNavigate } from "react-router-dom";
import "./footer.styles.scss";

const links = [
  { label: "Home", url: "/" },
  // { label: "Services", url: "/services" },
  { label: "Case Studies", url: "/case-studies" },
  // { label: "About", url: "/about" },
  // { label: "FAQs", url: "/faq" },
  { label: "Help Center", url: "/help-center" },
  { label: "Privacy Policy", url: "/privacy-policy" },
  { label: "Terms and Conditions", url: "/terms-and-conditions" },
];

const contactInfo = [
  {
    link: "contact@innovativemojo.com",
    href: "mailto:contact@innovativemojo.com",
  },
  { link: "+1 (213) 838-0155", href: "tel:+12138380155" },
  { link: "innovativemojo.com", href: "http://innovativemojo.com" },
];

const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className="footer-component">
      <div className="main-container">
        <div className="about-application">
          <img onClick={() => navigate("/")} src={logo} alt="" />
          <p className="description">
            At Innovative MOJO, we bring more than 20 years of experience to the
            table—but we believe that the cornerstone of our success lies in
            real conversations, with real people, tackling real challenges.
          </p>
        </div>
        <div className="links-and-contact">
          <div className="links-container">
            <div className="column">
              {links.slice(0, 5).map((link) => (
                <a href={link.url} key={link.label}>
                  {link.label}
                </a>
              ))}
            </div>
            <div className="column">
              {links.slice(5).map((link) => (
                <a href={link.url} key={link.label}>
                  {link.label}
                </a>
              ))}
            </div>
          </div>
          <div className="contact-container">
            {contactInfo.map((info) => (
              <a href={info.href} key={info.link}>
                {info.link}
              </a>
            ))}
          </div>
        </div>
        <BackgroundEffect />
      </div>
      <div className="rights-container">
        <p>© 2024 Innovative MOJO. All Rights Reserved.</p>
      </div>
    </div>
  );
};

export default Footer;
