import React, { useEffect, useState } from "react";
import { firestore } from "../../firebase/firebase";
import { getDateInYYYYMMDDFormat } from "../../utils/paginationFunctions";

import HomeFooter from "../../components/home-footer/home-footer.component";
import SmallBanner from "../../components/small-banner/small-banner.component";

import "../../components/small-banner/small-banner.styles.scss";
import "./individual-news.styles.scss";
import { useParams } from "react-router-dom";

const IndividualNews = () => {
  const params = useParams().id;
  const [article, setArticle] = useState([]);
  useEffect(() => {
    const snapShot = firestore
      .collection("Articles")
      .doc(params)
      .onSnapshot((snapshot) => {
        const data = snapshot.data();

        const articleData = {
          ...data,
          title: data.title,
          summary: data.summary,
          createdAt: getDateInYYYYMMDDFormat(data.timestamp),
          id: snapshot.id,
        };
        setArticle(articleData);
      });
    return () => snapShot();
  }, [params]);
  return (
    <div className="individual-news-container">
      <SmallBanner title={article.title} heading={article.summary} />
      <div className="container news-content">
        <img src={article.coverPhotoURL} alt="" />
        {article.htmlContent ? (
          <div dangerouslySetInnerHTML={{ __html: article.htmlContent }} />
        ) : (
          ""
        )}
      </div>

      <HomeFooter />
    </div>
  );
};

export default IndividualNews;
