import React from "react";

import "./proud-to-work-section.styles.scss";
import iviry from "../../assets/about/iviry.svg";
import meraki from "../../assets/about/meraki.svg";
import oedm from "../../assets/about/oedm.svg";
import pacific from "../../assets/about/pacific.svg";

const proudToWork = [meraki, iviry, pacific, oedm];
const ProudToWorkSection = () => {
  return (
    <div className="proud-to-work-component container">
      <h2>Proud to work with</h2>
      <div className="images-container">
        {proudToWork.map((item, index) => (
          <img alt="" key={index} src={item} />
        ))}
      </div>
    </div>
  );
};

export default ProudToWorkSection;
