"use client";
import React, { useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  Mousewheel,
} from "swiper/modules";
// import Button from "../ui/Button";
// import view from "../../assets/teambutton (1).png";
import view from "../../../assets/newpage/teambutton (1).png";
import "./Ourteam.css";


const TeamSlider = () => {

  const videoRef = useRef(null);

 
  useEffect(() => {
    const video = videoRef.current;
    
    if (video) {
      const playVideo = () => {
        // Check screen width
          video.play().catch(error => {
            console.error('Error attempting to play the video:', error);
          });
       
      };

      // Play or pause video based on screen width
      playVideo();

      // Add event listener for window resize
      window.addEventListener('resize', playVideo);

      // Cleanup event listener on component unmount
      return () => {
        window.removeEventListener('resize', playVideo);
      };
    }
  }, []);

  return (
    <>
      <Swiper
        className="TeamSlidermain"
        style={{
          "--swiper-pagination-color": "#8E7102",
          "--swiper-pagination-bullet-inactive-color": "#D9D9D9",
        }}
        modules={[
          Navigation,
          Pagination,
          Scrollbar,
          A11y,
          Autoplay,
          Mousewheel,
        ]}
        loop={true}
        spaceBetween={50}
        slidesPerView={1}
        speed={1500}
        autoplay={{
          delay: 2000,
        }}
       
        pagination={{ clickable: true }}
      >
        {/* 1 */}
        {/* <SwiperSlide>
          <div className="TeamSlidermain">
            <div className="team-content ">
              <h2 className="team-title text-center">Our Team:</h2>
              <h1 className="team-heading text-center">
                The Architects of Transformation
              </h1>
              <p className="team-para para mt-4 mb-4 text-center">
                At Innovative MOJO, we have assembled a team of passionate and
                experienced professionals who are dedicated to helping
                businesses thrive in the digital world. Our team consists of
                experts in SEO, graphic design, UI/UX design, website
                development, CRM implementation, lead generation, ad management,
                and content creation.
                <br />
                <br />
                With a combined experience of over 50 years in the industry, our
                team has a deep understanding of the ever-evolving digital
                landscape and how to leverage it for maximum results. We are
                constantly updating our skills and staying ahead of the curve to
                provide our clients with cutting-edge solutions
              </p>

              <div className="d-flex justify-content-center">
                <img className="team-view" src={view} alt="" />
              </div>
            </div>
          </div>
        </SwiperSlide> */}

        {/* 2 */}

        <SwiperSlide>
          <div className="TeamSlidermain" style={{cursor:"auto"}}>
            <div className="team-content ">
              <h2 className="team-title text-center">What We Do</h2>

<div className="">
  
<video
        ref={videoRef}
        className="cursor-pointer our-team-video"
        src="/ourteamvid.mp4"
        // poster="/images/home/youcan.png"
        width={1907.34}
        height={1182.23}
        loop
        playsInline
        controls
        muted
      ></video>
</div>



              {/* <h1 className="team-heading text-center">
                A Collaborative Journey
              </h1> */}
              
              {/* <p className="team-para para mt-4 mb-4 text-center">
                At Innovative MOJO, we believe in collaboration and
                transparency. Our process involves working closely with our
                clients every step of the way.
                <br />
                <br />
                At the heart of every successful digital venture lies a team of
                skilled professionals, and at Innovative MOJO, we're proud to
                have a crew that makes the impossible, possible. It's their
                expertise, creativity, and forward-thinking strategies that fuel
                our promise and deliver results beyond expectations. Whether
                it's through the precision of our SEO experts, the artistic
                flair of our graphic designers, or the technical savvy of our
                developers, each member plays a pivotal role.
                <br /> <br />
                They're not just workers; they're architects, crafting a
                masterpiece tailored specifically to your brand's needs. Before
                we introduce you to these magicians behind the curtain,
                understand that it is their relentless dedication and passion
                for innovation that empowers your business to flourish in the
                digital age
              </p>

              <div className="d-flex justify-content-center">
                <img className="team-view" src={view} alt="" />
              </div> */}
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default TeamSlider;
