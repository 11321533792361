import { Routes, Route, Navigate } from "react-router-dom";
import { useLocation } from "react-router-dom/dist";

import useAuth from "./customhooks/useAuth";

import FAQ from "./pages/faq/faq.page";
import Home from "./pages/home/home.page";
import NewPage from "./pages/newpage/newpage";
import About from "./pages/about/about.page";
import Contact from "./pages/contact/contact.page";
import Services from "./pages/services/services.page";
import MojoNews from "./pages/mojo-news/mojo-news.page";
import Portfolio from "./pages/portfolio/portfolio.page";
import HelpCenter from "./pages/help-center/help-center.page";
import GoogleAuth from "./pages/google-auth/google-auth.component";
import IndividualNews from "./pages/individual-news/individual-news";
import PrivacyPolicy from "./pages/privacy-policy/privacy-policy.page";
import MobileDevelopment from "./pages/mobile-dev/mobile-dev.component";
import CreateArticle from "./pages/create-article/create-article.component";
import ScrollToTop from "./components/scroll-to-top/scroll-to-top.component";
import AdminMenuBar from "./components/admin-menu-bar/admin-menu-bar.component";
import AdminArticlePage from "./pages/admin-article-page/admin-article-page.component";
import TermsAndConditions from "./pages/terms-and-conditions/terms-and-conditions.page";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import Engagement from "./pages/engagement/engagement.component";
import BusinessBoast from "./pages/business-boast/business-boast.component";
import BrandAwareness from "./pages/brand-awareness/brand-awareness.component";
import VSL from "./pages/vsl/vsl.component";
import Thankyou from "./pages/thankyou/thankyou.component";
import Booking from "./pages/booking/booking.component";
function App() {
  const location = useLocation();

  const allowedRoutes = [
    "/",
    "/services",
    "/contact",
    "/faq",
    "/about",
    "/privacy-policy",
    "/mojo-news",
    "/portfolio",
    "/help-center",
    "/terms-and-conditions",
    "/login",
    "/portfolio",
    "/enagagement",
  ]; // Add routes that should be accessible without login

  const userSignedIn = useAuth(allowedRoutes);
  if (userSignedIn === null) {
    return null;
  }

  return (
    <div
      className={`App ${
        location.pathname.includes("admin") || userSignedIn
          ? "no-background"
          : ""
      }`}
    >
      <ScrollToTop />
      {userSignedIn && location.pathname.includes("admin") && (
        <div className="sidebar">
          <AdminMenuBar />
        </div>
      )}
      <div
        className={`component ${
          location.pathname.includes("admin") && userSignedIn
            ? "calc-width"
            : ""
        }`}
      >
        <Routes>
          <Route path="/oldhome" element={<Home />} />
          <Route path="/" element={<NewPage />} />
          <Route path="/booking" element={<Booking />} />
          <Route path="/optin-simple" element={<Engagement />} />
          <Route path="/vsl" element={<VSL />} />
          <Route path="/optin-teaser" element={<BusinessBoast />} />
          <Route path="/thank-you" element={<Thankyou />} />
          <Route path="/optin-column" element={<BrandAwareness />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/about" element={<About />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/case-studies" element={<Portfolio />} />
          <Route path="/help-center" element={<HelpCenter />} />
          <Route path="/mojo-news" element={<MojoNews />} />
          <Route path="/mojo-news/:id" element={<IndividualNews />} />
          <Route path="/case-studies/:name/:id" element={<MobileDevelopment />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="/login" element={<GoogleAuth />} />
          <Route path="/admin-articles" element={<AdminArticlePage />} />
          <Route path="/admin-articles/create" element={<CreateArticle />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
