import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { firestore } from "../../firebase/firebase";
import { getDateInYYYYMMDDFormat } from "../../utils/paginationFunctions";

import arrowRight from "../../assets/arrow.svg";
import Navbarr from "../../components/navbar/navbar.component";
import NewsList from "../../components/news-list/news-list.component";
import HomeFooter from "../../components/home-footer/home-footer.component";
import "../../components/small-banner/small-banner.styles.scss";
import "./mojo-news.styles.scss";
const MojoNews = () => {
  const navigate = useNavigate();
  const [articles, setArticles] = useState([]);
  const [featured, setFeatured] = useState([]);

  useEffect(() => {
    const snapShot = firestore
      .collection("Articles")
      .orderBy("timestamp", "desc")
      .onSnapshot((snapshot) => {
        const articlesFromDb = snapshot.docs.map((doc) => {
          const data = doc.data();

          const articleData = {
            ...data,
            title: data.title,
            summary: data.summary,
            createdAt: getDateInYYYYMMDDFormat(data.timestamp),
            id: doc.id,
          };

          return articleData;
        });
        setArticles(articlesFromDb.filter((x) => !x.isFeatured));
        setFeatured(articlesFromDb.filter((x) => x.isFeatured));
      });
    return () => snapShot();
  }, []);

  return (
    <div className="mojo-news-container">
      <div className="small-banner-component">
        <Navbarr />
        <div className="banner-content-container container">
          <div className="section1-content">
            <h1>Featured Articles</h1>
            {featured.length ? (
              <div className="row">
                <div
                  className="rank1-featured col-12 col-lg-7"
                  onClick={() =>
                    navigate(
                      `/mojo-news/${
                        featured.find((x) => x.featuredRank === 1).id
                      }`
                    )
                  }
                >
                  <img
                    src={
                      featured.find((x) => x.featuredRank === 1).coverPhotoURL
                    }
                    alt=""
                  />
                  <div className="featured-data">
                    <h3>{featured.find((x) => x.featuredRank === 1).title}</h3>
                    <div className="news-description">
                      {featured.find((x) => x.featuredRank === 1).summary}
                    </div>
                    <p>
                      Read More
                      <img className="arrow-right" src={arrowRight} alt="" />
                    </p>
                  </div>
                </div>
                <div className="other-ranks col-12 col-lg-5">
                  <div
                    className="rank2"
                    onClick={() =>
                      navigate(
                        `/mojo-news/${
                          featured.find((x) => x.featuredRank === 2).id
                        }`
                      )
                    }
                  >
                    <img
                      src={
                        featured.find((x) => x.featuredRank === 2).coverPhotoURL
                      }
                      alt=""
                    />
                    <div>
                      <h3>
                        {featured.find((x) => x.featuredRank === 2).title}
                      </h3>
                      <div className="news-description">
                        {featured.find((x) => x.featuredRank === 2).summary}
                      </div>
                      <p>
                        Read More
                        <img className="arrow-right" src={arrowRight} alt="" />
                      </p>
                    </div>
                  </div>
                  <br />
                  <div
                    className="rank2"
                    onClick={() =>
                      navigate(
                        `/mojo-news/${
                          featured.find((x) => x.featuredRank === 3).id
                        }`
                      )
                    }
                  >
                    <img
                      src={
                        featured.find((x) => x.featuredRank === 3).coverPhotoURL
                      }
                      alt=""
                    />
                    <div>
                      <h3>
                        {featured.find((x) => x.featuredRank === 3).title}
                      </h3>
                      <div className="news-description">
                        {featured.find((x) => x.featuredRank === 3).summary}
                      </div>
                      <p>
                        Read More
                        <img className="arrow-right" src={arrowRight} alt="" />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="height-empty"></div>
            )}
          </div>
        </div>
      </div>
      <NewsList articles={articles} />

      <HomeFooter />
    </div>
  );
};

export default MojoNews;
